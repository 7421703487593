<template>
  <div class="box" :style="bg">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div class="content-header">
        <div class="reference">
          <div class="sign-btn" @click="handleActivity">
            <img src="@/assets/images/interactive/sign.png" alt="" />
            <span>签到</span>
          </div>
          <div class="sign-btn" @click="handleExam">
            <img src="@/assets/images/interactive/read.png" alt="" />
            <span>答题</span>
          </div>
          <div class="sign-btn" @click="handleVote">
            <img src="@/assets/images/interactive/toupiao.png" alt="" />
            <span>投票</span>
          </div>
          <div class="sign-btn" @click="handleMsg">
            <img src="@/assets/images/interactive/message.png" alt="" />
            <span>留言墙</span>
          </div>
        </div>
      </div>
      <div class="sign-list">
        <template v-if="activeName == 'activity'">
          <div class="sign-item" v-for="item in activityList" :key="item.id">
            <div class="sing-item-left">
              <span>{{ item.name }}</span>
            </div>
            <div class="sign-item-right" @click="handleQrCode(item)">
              <span class="click">点击可放大</span>
              <!-- <vue-qrcode value="Hello, World!" slot="reference" /> -->
              <!-- <el-popover
                placement="bottom"
                width="4rem"
                trigger="click"
                style="border: none"
              >
                <div class="pop-content">
                  <vue-qrcode
                    tag="img"
                    :value="
                      WX_URL +
                      `/sign?activityId=${item.id}&deviceCode=${deviceCode}&userId=${user.id}`
                    "
                    class="qrcode"
                  />
                  <p>微信扫码签到</p>
                </div>
                <vue-qrcode
                  tag="img"
                  :value="
                    WX_URL +
                    `/sign?activityId=${item.id}&deviceCode=${deviceCode}&userId=${user.id}`
                  "
                  style="width: 1.16rem; height: 1.16rem"
                  slot="reference"
                />
              </el-popover> -->
              <vue-qrcode
                tag="img"
                :value="
                  WX_URL +
                  `/sign?activityId=${item.id}&deviceCode=${deviceCode}&userId=${user.id}`
                "
                style="width: 1.16rem; height: 1.16rem"
                slot="reference"
              />
            </div>
          </div>
        </template>

        <template v-else-if="activeName == 'exam'">
          <div class="sign-item" v-for="item in examList" :key="item.id">
            <div class="sing-item-left">
              <span>{{ item.name }}</span>
            </div>
            <div class="sign-item-right" @click="JumpToAnswer(item)">
              点击进入>
            </div>
          </div>
        </template>

        <template v-else-if="activeName == 'vote'">
          <div class="sign-item" v-for="item in voteList" :key="item.id">
            <div class="sing-item-left">
              <span>{{ item.name }}</span>
            </div>
            <div class="sign-item-right">
              <div class="sign-item-right" @click="JumpToVote(item)">
                点击进入>
              </div>
            </div>
          </div>
        </template>

        <template v-else>
          <!-- <el-carousel
            height="450px"
            :interval="2000"
            :autoplay="true"
            indicator-position="none"
          >
            <el-carousel-item
              v-for="(item, index) in Math.ceil(msgList.length / 3)"
              :key="index"
            >
              <div class="carousel-container">
                <div
                  class="msg-container"
                  v-for="msg in msgList.slice(index * 3, index * 3 + 3)"
                  :key="msg.id"
                >
                  <div class="msg-header">
                    <img
                      class="msg-header__title"
                      :src="msg.wxAvatarUrl"
                      alt=""
                    />
                  </div>
                  <div class="msg-content">
                    {{ msg.content }}
                  </div>
                  <div class="msg-date">{{ msg.createTime }}</div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel> -->

          <transition-group name="fade" tag="ul" class="list">
            <li v-for="item in initMsgList" :key="item.id" class="list-item">
              <div class="msg-header">
                <img class="msg-header__title" :src="item.wxAvatarUrl" alt="" />
              </div>
              <div class="msg-content">
                {{ item.content }}
              </div>
              <div class="msg-date">{{ item.contentTime }}</div>
            </li>
          </transition-group>
        </template>
      </div>
    </div>
    <div class="modal" v-if="showModal" @click.stop="showModal = false">
      <div class="qrcode">
        <vue-qrcode
          tag="img"
          :value="
            WX_URL +
            `/sign?activityId=${itemId}&deviceCode=${deviceCode}&userId=${user.id}`
          "
        />
        <p>微信扫码签到</p>
      </div>
    </div>
    <menu-pop :activeItem="4"></menu-pop>
    <back :pageDepth="2"></back>
  </div>
</template>

<script>
import menuPop from '@/components/menuPop';
import back from '@/components/back';
import BrowserType from '@/utils/user-agent.js';
import navRouter from '@/components/navRouter';
// import { API_MODULE } from "@/request/api";
const redIcon = require('@/assets/images/dangHistory/home.png');
const blueIcon = require('@/assets/images/dangHistory/home-blue.png');
import { mapGetters, mapMutations } from 'vuex';

import vueQrcode from '@chenfengyuan/vue-qrcode';

export default {
  name: 'WISDOM_INTERACTION',
  components: {
    menuPop,
    navRouter,
    back,
    vueQrcode,
  },
  data() {
    return {
      icon: redIcon,

      showModal: false,
      bg: {
        backgroundColor: '#E03838',
      },
      bgRed: {
        backgroundColor: '#E03838',
      },
      bgBlue: {
        backgroundColor: '#2070DB',
      },
      activeName: 'activity',
      activityList: [],
      examList: [],
      voteList: [],
      msgList: [],
      allMsgList: [],
      initMsgList: [],
      nextIndex: 4,
      // 设备编码
      deviceCode: '',
      user: {},
      timer: null,
      userAgent: {},
      itemId: undefined,
    };
  },
  methods: {
    ...mapMutations('skin', ['setRouterList']),
    getActivityList() {
      this.GET(this.API_MODULE.GET_SIGN_IN_ACTIVITY)
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.activityList = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getAnswerList() {
      this.GET(this.API_MODULE.GET_EXAM_LIST).then((res) => {
        if (res.code == 0 || res.code == 200) {
          this.examList = res.data;
        }
      });
    },

    getVoteList() {
      this.GET(this.API_MODULE.GET_VOTE_LIST).then((res) => {
        if (res.code == 0 || res.code == 200) {
          this.voteList = res.data;
        }
      });
    },

    getMsgList() {
      this.GET(this.API_MODULE.GET_MSG_LIST, { snCode: this.deviceCode }).then(
        (res) => {
          if (res.code == 0 || res.code == 200) {
            this.msgList = res.data;
          }
        }
      );
    },
    handleAgent() {
      if (this.SNCODE_STATRUS == 1) {
        // 这两个方法是在index.html 引入的js文件里的
        // eslint-disable-next-line no-undef
        init_qt();
        // eslint-disable-next-line no-undef
        GetCpuID();
        this.deviceCode = document.getElementById('cpu_id').value;
      } else {
        this.deviceCode = '';
      }
    },
    handleDevice() {
      if (this.SNCODE_STATRUS == 1) {
        window.androidAPI.callAndroidMethod('getDeviceId', '');
        this.deviceCode = document.getElementById('device_id').value;
      } else {
        this.deviceCode = '';
      }
    },
    JumpToAnswer(item) {
      this.$router.push({
        path: '/layout/interactive/answer',
        query: { id: item.id },
      });

      const path = `/layout/interactive/answer?id=${item.id}`;
      this.useRouterList.push({
        name: item.name,
        path: path,
      });
      this.setRouterList(this.useRouterList, path);
    },
    JumpToVote(item) {
      this.$router.push({
        path: '/layout/interactive/vote',
        query: { id: item.id, status: item.status },
      });
      const path = `/layout/interactive/vote?id=${item.id}&status=${item.status}`;
      this.useRouterList.push({
        name: item.name,
        path: path,
      });
      this.setRouterList(this.useRouterList, path);
    },
    handleActivity() {
      this.activeName = 'activity';
      this.getActivityList();
      this.clearTimer();
    },

    handleExam() {
      this.activeName = 'exam';
      this.getAnswerList();
      this.clearTimer();
    },

    handleVote() {
      this.activeName = 'vote';
      this.getVoteList();
      this.clearTimer();
    },

    getInitMsgList() {
      this.GET(this.API_MODULE.GET_MSG_LIST, { snCode: this.deviceCode }).then(
        (res) => {
          if (res.code == 0 || res.code == 200) {
            this.allMsgList = res.data;
            this.initMsgList = res.data.slice(0, 4);
          }
        }
      );
    },

    getNewMsgList() {
      this.GET(this.API_MODULE.GET_MSG_LIST, { snCode: this.deviceCode }).then(
        (res) => {
          if (res.code == 0 || res.code == 200) {
            const data = res.data;
            if (data.length - this.allMsgList.length > 0) {
              const newData = data.slice(
                0,
                data.length - this.allMsgList.length
              );
              newData.forEach((element) => {
                element.isNew = true;
              });
              this.allMsgList.splice(this.nextIndex, 0, ...newData);
            }
          }
        }
      );
    },
    handleMsg() {
      this.activeName = 'msg';
      this.getInitMsgList();
      let that = this;
      this.timer = setInterval(function () {
        that.getNewMsgList();
        // nextIndex === 所有消息时，重置循环
        console.log(that.initMsgList.length < 4);
        if (that.initMsgList.length < 4) {
          that.nextIndex = that.initMsgList.length;
          that.allMsgList[that.nextIndex] &&
            that.initMsgList.push(that.allMsgList[that.nextIndex++]);
        } else {
          if (that.nextIndex === that.allMsgList.length) that.nextIndex = 0;
          that.initMsgList.push(that.allMsgList[that.nextIndex++]);
          that.initMsgList.splice(0, 1);
        }
      }, 2500);
    },
    handleQrCode(item) {
      this.itemId = item.id;
      this.showModal = true;
    },
    clearTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
  },
  computed: {
    ...mapGetters('skin', ['useSubject', 'useRouterList']),
  },
  created() {
    if (this.useSubject === 'red') {
      this.icon = redIcon;
      this.bg = this.bgRed;
    } else {
      this.icon = blueIcon;
      this.bg = this.bgBlue;
    }
    if (window.localStorage.getItem('isd')) {
      this.isd = window.localStorage.getItem('isd');
    }
    this.userAgent = BrowserType();
    if (!this.isd) {
      if (this.userAgent.system === 'android') {
        this.handleDevice();
      }
      if (this.userAgent.system === 'windows') {
        this.handleAgent();
      }
    } else {
      this.deviceCode = '0000000000';
    }
    this.user = JSON.parse(window.localStorage.getItem('USER'));
    this.getActivityList();
    // 保存路径
    this.setRouterList([{ name: '智慧互动', path: this.$route.fullPath }]);
  },

  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.icon = redIcon;
        this.bg = this.bgRed;
      } else {
        this.icon = blueIcon;
        this.bg = this.bgBlue;
      }
    },
  },
  destroyed() {
    this.clearTimer();
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'Answer' && to.name !== 'Vote') {
      this.$store.commit('REMOVE_KEEP_ALIVE', from.name);
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
@import '@/themes/_handle.scss';

.pop-content {
  width: 4rem;
  height: 4.66rem;
  @include background_color('background-high-8');
  text-align: center;
  color: #ffffff;
  font-size: 0.36rem;

  .qrcode {
    width: 3rem;
    margin-top: 0.56rem;
    margin-bottom: 0.2rem;
  }
}

.box {
  min-height: 24.2rem;
  background-size: cover;
  background-repeat: no-repeat;

  .content {
    padding: 0.4rem;

    .content-header {
      /*height: 1rem;*/

      .reference {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .sign-btn {
          /*float: right;*/
          width: 6.6rem;
          height: 1.6rem;
          line-height: 1.6rem;
          background: #cca766;
          opacity: 1;
          border-radius: 0.05rem;
          font-size: 0.31rem;
          color: #ffffff;
          text-align: center;
          border: 1px solid #cca766;
          margin-bottom: 0.4rem;
          img {
            margin-right: 0.2rem;
            vertical-align: text-top;
            width: 0.3rem;
            height: 0.3rem;
          }
        }
      }

      .sign-btn:active {
        border: 1px solid #fff;
      }
    }

    .sign-list {
      margin-top: 0.4rem;
      height: 20.4rem;
      overflow-x: hidden;
      overflow-y: scroll;
      .sign-item {
        width: 100%;
        min-height: 1.6rem;
        background: #ffffff;
        border-width: 0.1rem;
        border-style: solid;
        @include border_color('border-light');
        border-radius: 0.05rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 0.78rem;
        padding-right: 1rem;
        margin-bottom: 0.6rem;

        .sing-item-left {
          font-size: 0.48rem;
          color: #555555;
          display: flex;
          align-items: center;
          width: 8.5rem;

          img {
            width: 1rem;
            height: 1rem;
            border-radius: 0.1rem;
            font-weight: bold;
            margin-right: 0.5rem;
          }
        }

        .sign-item-right {
          font-weight: bold;
          font-size: 0.36rem;
          display: flex;
          align-items: center;

          @include font_color('font-medium');

          .click {
            color: #c3c3c3;
          }

          .exam-click {
            color: #c3c3c3;
            font-size: 0.3rem;
          }
        }
      }
      // .carousel-container {
      //   display: flex;
      //   .msg-container {
      //     padding-top: 0.7rem;
      //     display: flex;
      //     flex-direction: column;
      //     margin-right: 0.8rem;
      //     background-color: #f7f7f7;

      //     .msg-header__title {
      //       border-radius: 100%;
      //     }

      //     .msg-content {
      //       width: 3.2rem;
      //       color: #6f6f6f;
      //       font-size: 0.28rem;
      //       padding: 0 0.4rem;
      //     }
      //     .msg-date {
      //       font-size: 0.24rem;
      //       color: #b2b2b2;
      //       align-self: flex-end;
      //       padding-right: 0.4rem;
      //       padding-bottom: 0.7rem;
      //     }
      //   }
      // }
    }
  }
  .modal {
    position: fixed;
    top: 1.4rem;
    left: 0;
    right: 0;
    bottom: 0;
    @include background_color('background-high-8');

    .qrcode {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 0.56rem;
      color: #ffffff;

      img {
        width: 8rem;
      }
    }
  }
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter {
  transform: translateY(20px);
}

.fade-leave-to {
  transform: translateY(-20px);
}

.fade-leave-active {
  position: absolute;
  right: 0;
}

/*leave-active必须绝对定位移除元素才有过渡效果*/
.list {
  display: flex;
  padding-top: 0.4rem;
  flex-direction: column;

  .list-item {
    transition: all 0.6s;
    list-style: none;
    display: flex;
    margin-bottom: 0.8rem;
    background-color: #f7f7f7;
    position: relative;

    .msg-header__title {
      height: 5rem;
      width: 4rem;
      border-radius: 5%;
    }

    .msg-content {
      width: 100%;
      height: 3.7rem;
      color: #6f6f6f;
      font-size: 0.28rem;
      padding: 0.4rem;
      text-align: left;
    }
    .msg-date {
      font-size: 0.24rem;
      color: #b2b2b2;
      align-self: flex-end;
      padding-bottom: 0.1rem;
      position: absolute;
      right: 0.1rem;
    }
  }
}
</style>
